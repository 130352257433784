import {Text} from "../providers/Language"

import path from './paths'

import theme_cover from "img/home_grid/1_theme/medieval_spectacle_show_feu_artifice_animation_clowns_dans_la_sciure_horde_ copie.jpg"
import event_cover from "img/home_grid/2_evenements/01_couverture_evenement_animations_lutins_noel_jongleur_echassier_clowns_dans_la_sciure_ok.jpg"
import activities_cover from "img/home_grid/3_animations/01_couverture_animations_clowns_dans_la_sciure.jpg"
import workshop_cover from "img/home_grid/4_atelier/01_couverture_atelier_clowns dans la sciure.jpg"
import new_cover from "img/home_grid/5_nouveautes/01_couverture_nouveaute_jongleur feu_pyrotechnique_clowns dans la sciure.jpg"
import droom_cover from "img/home_grid/6_en_loge/01_couverture_loge_clowns dans la sciure.jpg"

const homeGridShows = [
    {
        title: <Text id={'navThemeColumn'} />,
        shortDescription: <Text id={'universeGridFirstDescription'}/>,
        poster: theme_cover,
        path: path.themes,
        toShowHomeGrid: true
    },
    {
        title: <Text id={'navEventsColumn'}/>,
        shortDescription: <Text id={'universeGridSecondDescription'}/>,
        poster: event_cover,
        path: path.events,
        toShowHomeGrid: true
    },
    {
        title: <Text id={'navActivityColumn'} />,
        shortDescription: <Text id={'universeGridThirdDescription'}/>,
        poster: activities_cover,
        path: path.activities,
        toShowHomeGrid: true
    },
    {
        title: <Text id={'universeGridFourth'} />,
        shortDescription: <Text id={'universeGridFourthDescription'}/>,
        poster: workshop_cover,
        path: path.troupe + '#troupeWorkshop',
        toShowHomeGrid: true
    },
    {
        title: <Text id={'universeGridFifth'}/>,
        shortDescription: <Text id={'universeGridFifthDescription'}/>,
        poster: new_cover,
        path: path.new,
        toShowHomeGrid: true
    },
    {
        title: <Text id={'universeGridSixth'}/> ,
        shortDescription: <Text id={'universeGridSixthDescription'}/>,
        poster: droom_cover,
        path: path.troupe + '#troupeBTS',
        toShowHomeGrid: true
    },
]

export default homeGridShows