import dynamic from "next/dynamic"
import Link from 'next/link'
import PageProvider, {useWindowSize} from "../services/Global"
import {Text} from "../services/providers/Language"

const HeadPage = dynamic(() => import("../components/pageComponent/Head"))
const HomeCarouselItem = dynamic(() => import("../components/carousel/HomeCarouselItem"))
const SimpleImageGrid = dynamic(() => import("../components/SimpleImageGrid"))
const SponsorCarousel = dynamic(() => import("../components/carousel/SponsorCarousel"))
const InstaStories = dynamic(() => import("../components/pageComponent/InstaStories"))
const BigFadeButton = dynamic(() => import("../components/pageComponent/buttons/BigFadeButton"))

import path from '../services/contents/paths'
import carouselContent from "../services/contents/carouselContent"
import homeGridShows from "../services/contents/homeGridShows"
import instaHeader from "../services/contents/instaHeader"


function Home() {
    const [screenWidth] = useWindowSize()
    const homeStories = [
        {
            url: '/video/home/insta/sharivari_echassier_jongleur_feu_acrobate_show.mp4',
            type: 'video',
            header: instaHeader
        },
        {
            url: '/video/home/insta/Stories_Instagram_wadioui.mp4',
            type: 'video',
            header: instaHeader
        },
        {
            url: '/video/home/insta/WhatsApp_Video_2021-12-31_at_12.25.58.mp4',
            type: 'video',
            header: instaHeader
        },
        {
            url: '/video/home/insta/WhatsApp_Video_2022-01-27_at_07.52.10.mp4',
            type: 'video',
            header: instaHeader
        },
        {
            url: '/video/home/insta/WhatsApp_Video_2022-01-27_at_07.54.39.mp4',
            type: 'video',
            header: instaHeader
        },
        {
            url: '/video/home/insta/WhatsApp_Video_2022-02-18_at_07.35.03_(2).mp4',
            type: 'video',
            header: instaHeader
        },
        {
            url: '/video/home/insta/WhatsApp_Video_2022-02-18_at_07.35.03_(3).mp4',
            type: 'video',
            header: instaHeader
        },
        {
            url: '/video/home/insta/WhatsApp_Video_2022-02-18_at_07.38.29.mp4',
            type: 'video',
            header: instaHeader
        },
    ]
    const styles = {
        homeCarousel: {
            height: '35em'
        },
        videoBackground: {
            backgroundColor: 'black',
            height: '50em'
        },
    }
    const date = new Date()
    const currentDay = date.getDate()
    let currentMonth = date.getMonth() + 1

    if (currentMonth >= 1 && currentMonth < 10) {
        currentMonth = '0' + currentMonth
    }

    const currentCarouselContent = carouselContent.filter((elem) => {
        const startDate = elem.startDate.split('/')
        const endDate = elem.endDate.split('/')
        const calcStartDate = parseInt(startDate[1]) * 31 + parseInt(startDate[0])
        const calcEndDate = parseInt(endDate[1]) * 31 + parseInt(endDate[0])
        const calcCurrentDate = currentMonth * 31 + currentDay

        if ((calcStartDate <= calcCurrentDate) && (calcCurrentDate <= calcEndDate)) {
            return elem
        }
    })

    return (
        <PageProvider>
            <HeadPage title='homeMetaTitle' description='homeMetaDescription' posterImage={currentCarouselContent[0]}/>

            <div className='uk-position-relative uk-visible-toggle uk-light'
                 data-uk-slideshow='animation: pull; ratio: true; autoplay: true; autoplay-interval: 8000;'>
                <ul className='uk-slideshow-items' style={styles.homeCarousel}>
                    {
                        currentCarouselContent.map((elem, id) => {
                            return <HomeCarouselItem key={id} content={elem}/>
                        })
                    }
                </ul>

                <div className={screenWidth < 959 ? 'display-none' : 'uk-display-block'}>
                    <Link href='#0' tabIndex={0} className="uk-position-center-left uk-position-small uk-hidden-hover" data-uk-slidenav-previous=''
                          data-uk-slideshow-item="previous"/>
                    <Link href='#0' tabIndex={0} className="uk-position-center-right uk-position-small uk-hidden-hover" data-uk-slidenav-next=''
                          data-uk-slideshow-item="next"/>
                </div>
            </div>

            <div className='uk-section uk-section-small'>
                <div className='uk-container uk-flex uk-flex-column uk-flex-center'>
                    <blockquote cite="#" className='uk-text-center uk-margin-medium-bottom'>
                        <Text id={'introductionMessage'}/>
                    </blockquote>

                    <div className='uk-margin-auto'>
                        <BigFadeButton link={path.shows} text={'navDesktopAllShows'}/>
                    </div>
                </div>
            </div>

            <div className='uk-position-relative uk-overflow-hidden home-video-banner'>
                <video loop muted playsInline className='uk-position-absolute home-video' data-uk-video='autoplay: inview' data-uk-cover=''>
                    <source src={'/video/home/accueil_clowns_dans_la_sciure_videos_cdls.mp4'} type={'video/mp4'}/>
                </video>
            </div>

            <SimpleImageGrid items={homeGridShows} sectionTitle='universeTitle'/>

            <InstaStories stories={homeStories}/>

            <SponsorCarousel/>
        </PageProvider>
    )
}

export default Home