import {Text} from "../providers/Language"
import path from './paths'

import january1 from 'img/carousel/january/new_year/1_event_evenementiel_show_spectacle.jpg'
import january2 from 'img/carousel/january/new_year/2_evenementiel_show_event_spectacle_ateliers.jpg'
import january3 from 'img/carousel/january/new_year/3_event_evenementiel_show_spectacle_ateliers.jpg'
import january4 from 'img/carousel/january/01_feu_cracheur_event_evenementiel_festival_show_spectacles_pyrotechnie.jpg'
import january5 from 'img/carousel/january/02_saint_valentin.jpg'
import january6 from 'img/carousel/january/03_carnaval.jpg'

import february1 from 'img/carousel/february/1_carnaval_event_echassier_acrobate_jongleur_char_elegant_chic.jpg'
import february2 from 'img/carousel/february/2_carnaval_bulles_bubbles_echassier_acrobate_jongleur_char_steampunk.jpg'
import february3 from 'img/carousel/february/3_atelier bulles_maquillage_jongle_echasse_enfants_musique_evenementiel.jpg'

import march1 from 'img/carousel/march/01_erotisme_striptease_bondy et maia.jpg'
import march2 from 'img/carousel/march/02_art de rue_Bim turton_wotan project.jpg'
import march3 from 'img/carousel/march/03_gala_ce_Lady and gentleled\'s_light me up.jpg'

import april1 from 'img/carousel/april/1_cosplay_acrobate_tarzan_blackpanther_porte_acrobatique_feu_show_evenementiel.jpg'
import april2 from 'img/carousel/april/2_atelier bulles_maquillage_jongleur_echasse_enfants_musique_evenementiel.jpg'
import april3 from 'img/carousel/april/3_cosplay_alien_giger_feu_show_evenementiel_clubbing_jungle_monstre_event_accueil.jpg'

import may1 from 'img/carousel/may/1_festivals_rue_event_spectacle_show_enfants_echassier_theatre_cirque.jpg'
import may2 from 'img/carousel/may/2_steampunk_cracheursfeu_firebreather_festival_fire_feu_event_evnementiel.jpg'
import may3 from 'img/carousel/may/3_fete_event_spectacle_show_echassier_theatre_cirque_evenementiel_centre_commerciaux.jpg'

import june1 from 'img/carousel/june/1_Mariage_weddings_light_mulineux_mime_chic_elegant_echassier_acrobate_jongleur_accueil_event.jpg'
import june2 from 'img/carousel/june/2_fete_nationale_Cracheurfeu_firebreather_fire_pyrotechnie_artifices_show.jpg'
import june3 from 'img/carousel/june/3_fete_musique_steampunk_echassier_acrobate_jongleur_show_theatre.jpg'

import july1 from 'img/carousel/july/1_clubbing_discotheque_robots_leds_lasers_event_show_strip_firebreather.jpg'
import july2 from 'img/carousel/july/2_festival_rue_steampunk_musique_char_bulles_acrobate_jongleur_echassier_evenementiel.jpg'
import july3 from 'img/carousel/july/3_fete_sorcieres_bruixes_halloween_spirit_arobate_contorsionniste_event_momies_show.jpg'

import august1 from 'img/carousel/august/1_atelier_bubbles_ bulles_maquillage_jongleur_echasse_enfants_musique_evenementiel.jpg'
import august2 from 'img/carousel/august/2_erotisme_striptease_bdsm_salontatouage_agde_evenementiel_echassier_latex_vinyle.jpg'
import august3 from 'img/carousel/august/3_festival_inauguration_lancementsproduits_animations_bulles_bubbles_event_evenementiel_spectacles.jpg'

import september1 from 'img/carousel/september/1_cosplay_acrobate_tarzan_blackpanther_firebreather_alien_feu_show.jpg'
import september2 from 'img/carousel/september/2_festival_rue_theatre_musique_char_bulles_acrobate_jongleur_echassier_e.jpg'
import september3 from 'img/carousel/september/3_horde_event_sorcieres_halloween_firebreather_event_show_diables_echasse.jpg'

import october1 from 'img/carousel/october/1_fete_sorcieres_bruixes_halloween_arobate_spirit_contorsionniste_event_momies.jpg'
import october2 from 'img/carousel/october/2_halloween_musique_steampunk_echassier_acrobate_jongleur_show_theatre_event.jpg'
import october3 from 'img/carousel/october/3_halloween_mexique_mummy_event_evenementiel_show_scary_echassier_clubbing.jpg'

import november1 from 'img/carousel/november/1_festival_rue_theatre_musique_char_bulles_acrobate_echassier_jongleur.jpg'
import november2 from 'img/carousel/november/2_clubbing_discotheque_robots_leds_lasers_event_show_light_firebreather.jpg'
import november3 from 'img/carousel/november/3_christmas_Noel_lutins_echassier_acrobate_jongleur_elfe_spirit_event.jpg'

import december1 from 'img/carousel/december/1_christmas_Noel_lutins_echassier_acrobate_jongleur_elfe_spirit_event_show.jpg'
import december2 from 'img/carousel/december/2_clubbing_discotheque_robots_leds_lasers_reveillon_light_firebreather.jpg'
import december3 from 'img/carousel/december/3_reveillon_rue_theatre_musique_char_bulles_acrobate_echassier_jongleur_newyear.jpg'
import december4 from 'img/carousel/december/4_pyrotechnie_steampunk_musique_char_bulles_acrobate_jongleur_echassier_evenementiel.jpg'

const carouselContent = [
    {
        title: <Text id={'carouselJanuary1Title'}/>,
        description: <Text id={'carouselJanuary1Description'}/>,
        cover: january1,
        path: path.troupe,
        startDate: '01/01',
        endDate: '03/01'
    },
    {
        title: <Text id={'carouselJanuary2Title'}/>,
        description: <Text id={'carouselJanuary2Description'}/>,
        cover: january2,
        path: path.shows,
        startDate: '01/01',
        endDate: '03/01'
    },
    {
        title: <Text id={'carouselJanuary3Title'}/>,
        description: <Text id={'carouselJanuary3Description'}/>,
        cover: january3,
        path: path.instagram,
        startDate: '01/01',
        endDate: '03/01'
    },
    {
        title: <Text id={'carouselJanuary4Title'}/>,
        description: <Text id={'carouselJanuary4Description'}/>,
        cover: january4,
        path: path.pyrotechnicsShow,
        startDate: '03/01',
        endDate: '15/02'
    },
    {
        title: <Text id={'carouselJanuary5Title'}/>,
        description: <Text id={'carouselJanuary5Description'}/>,
        cover: january5,
        path: path.valentine,
        startDate: '03/01',
        endDate: '15/02'
    },
    {
        title: <Text id={'carouselJanuary6Title'}/>,
        description: <Text id={'carouselJanuary6Description'}/>,
        cover: january6,
        path: path.fireCoal,
        startDate: '03/01',
        endDate: '15/02'
    },

    {
        title: <Text id={'carouselFebruary1Title'}/>,
        description: <Text id={'carouselFebruary1Description'}/>,
        cover: february1,
        path: path.carnival,
        startDate: '15/02',
        endDate: '29/02'
    },
    {
        title: <Text id={'carouselFebruary2Title'}/>,
        description: <Text id={'carouselFebruary2Description'}/>,
        cover: february2,
        path: path.streetFestival,
        startDate: '15/02',
        endDate: '29/02'
    },
    {
        title: <Text id={'carouselFebruary3Title'}/>,
        description: <Text id={'carouselFebruary3Description'}/>,
        cover: february3,
        path: path.easter,
        startDate: '01/02',
        endDate: '29/02'
    },

    {
        title: <Text id={'carouselMarch1Title'}/>,
        description: <Text id={'carouselMarch1Description'}/>,
        cover: march1,
        path: path.eroticism,
        startDate: '01/03',
        endDate: '31/03'
    },
    {
        title: <Text id={'carouselMarch2Title'}/>,
        description: <Text id={'carouselMarch2Description'}/>,
        cover: march2,
        path: path.streetFestival,
        startDate: '01/03',
        endDate: '31/03'
    },
    {
        title: <Text id={'carouselMarch3Title'}/>,
        description: <Text id={'carouselMarch3Description'}/>,
        cover: march3,
        path: path.privateShow,
        startDate: '01/03',
        endDate: '31/03'
    },

    {
        title: <Text id={'carouselApril1Title'}/>,
        description: <Text id={'carouselApril1Description'}/>,
        cover: april1,
        path: path.cinema,
        startDate: '01/04',
        endDate: '30/04'
    },
    {
        title: <Text id={'carouselApril2Title'}/>,
        description: <Text id={'carouselApril2Description'}/>,
        cover: april2,
        path: path.workshops,
        startDate: '01/04',
        endDate: '30/04'
    },
    {
        title: <Text id={'carouselApril3Title'}/>,
        description: <Text id={'carouselApril3Description'}/>,
        cover: april3,
        path: path.cosplay,
        startDate: '01/04',
        endDate: '30/04'
    },

    {
        title: <Text id={'carouselMay1Title'}/>,
        description: <Text id={'carouselMay1Description'}/>,
        cover: may1,
        path: path.streetFestival,
        startDate: '01/05',
        endDate: '31/05'
    },
    {
        title: <Text id={'carouselMay2Title'}/>,
        description: <Text id={'carouselMay2Description'}/>,
        cover: may2,
        path: path.steampunk,
        startDate: '01/05',
        endDate: '31/05'
    },
    {
        title: <Text id={'carouselMay3Title'}/>,
        description: <Text id={'carouselMay3Description'}/>,
        cover: may3,
        path: path.parentsDay,
        startDate: '01/05',
        endDate: '31/05'
    },

    {
        title: <Text id={'carouselJune1Title'}/>,
        description: <Text id={'carouselJune1Description'}/>,
        cover: june1,
        path: path.wedding,
        startDate: '01/06',
        endDate: '30/06'
    },
    {
        title: <Text id={'carouselJune2Title'}/>,
        description: <Text id={'carouselJune2Description'}/>,
        cover: june2,
        path: path.nationalDay,
        startDate: '01/06',
        endDate: '30/06'
    },
    {
        title: <Text id={'carouselJune3Title'}/>,
        description: <Text id={'carouselJune3Description'}/>,
        cover: june3,
        path: path.musicFestival,
        startDate: '01/06',
        endDate: '30/06'
    },

    {
        title: <Text id={'carouselJuly1Title'}/>,
        description: <Text id={'carouselJuly1Description'}/>,
        cover: july1,
        path: path.clubbing,
        startDate: '01/07',
        endDate: '31/07'
    },
    {
        title: <Text id={'carouselJuly2Title'}/>,
        description: <Text id={'carouselJuly2Description'}/>,
        cover: july2,
        path: path.streetFestival,
        startDate: '01/07',
        endDate: '31/07'
    },
    {
        title: <Text id={'carouselJuly3Title'}/>,
        description: <Text id={'carouselJuly3Description'}/>,
        cover: july3,
        path: path.witchDay,
        startDate: '01/07',
        endDate: '31/07'
    },

    {
        title: <Text id={'carouselAugust1Title'}/>,
        description: <Text id={'carouselAugust1Description'}/>,
        cover: august1,
        path: path.workshops,
        startDate: '01/08',
        endDate: '31/08'
    },
    {
        title: <Text id={'carouselAugust2Title'}/>,
        description: <Text id={'carouselAugust2Description'}/>,
        cover: august2,
        path: path.latexVinyl,
        startDate: '01/08',
        endDate: '31/08'
    },
    {
        title: <Text id={'carouselAugust3Title'}/>,
        description: <Text id={'carouselAugust3Description'}/>,
        cover: august3,
        path: path.product,
        startDate: '01/08',
        endDate: '31/08'
    },

    {
        title: <Text id={'carouselSeptember1Title'}/>,
        description: <Text id={'carouselSeptember1Description'}/>,
        cover: september1,
        path: path.cinema,
        startDate: '01/09',
        endDate: '30/09'
    },
    {
        title: <Text id={'carouselSeptember2Title'}/>,
        description: <Text id={'carouselSeptember2Description'}/>,
        cover: september2,
        path: path.streetFestival,
        startDate: '01/09',
        endDate: '30/09'
    },
    {
        title: <Text id={'carouselSeptember3Title'}/>,
        description: <Text id={'carouselSeptember3Description'}/>,
        cover: september3,
        path: path.halloween,
        startDate: '01/09',
        endDate: '30/09'
    },

    {
        title: <Text id={'carouselOctober1Title'}/>,
        description: <Text id={'carouselOctober1Description'}/>,
        cover: october1,
        path: path.halloween,
        startDate: '01/10',
        endDate: '31/10'
    },
    {
        title: <Text id={'carouselOctober2Title'}/>,
        description: <Text id={'carouselOctober2Description'}/>,
        cover: october2,
        path: path.fireCoal,
        startDate: '01/10',
        endDate: '31/10'
    },
    {
        title: <Text id={'carouselOctober3Title'}/>,
        description: <Text id={'carouselOctober3Description'}/>,
        cover: october3,
        path: path.halloween,
        startDate: '01/10',
        endDate: '31/10'
    },

    {
        title: <Text id={'carouselNovember1Title'}/>,
        description: <Text id={'carouselNovember1Description'}/>,
        cover: november1,
        path: path.christmas,
        startDate: '01/11',
        endDate: '26/12'
    },
    {
        title: <Text id={'carouselNovember2Title'}/>,
        description: <Text id={'carouselNovember2Description'}/>,
        cover: november2,
        path: path.newYearEve,
        startDate: '01/11',
        endDate: '26/12'
    },
    {
        title: <Text id={'carouselNovember3Title'}/>,
        description: <Text id={'carouselNovember3Description'}/>,
        cover: november3,
        path: path.wadioui,
        startDate: '01/11',
        endDate: '30/11'
    },

    {
        title: <Text id={'carouselDecember1Title'}/>,
        description: <Text id={'carouselDecember1Description'}/>,
        cover: december1,
        path: path.wadioui,
        startDate: '01/12',
        endDate: '26/12'
    },
    {
        title: <Text id={'carouselDecember2Title'}/>,
        description: <Text id={'carouselDecember2Description'}/>,
        cover: december2,
        path: path.newYearEve,
        startDate: '27/12',
        endDate: '31/12'
    },
    {
        title: <Text id={'carouselDecember3Title'}/>,
        description: <Text id={'carouselDecember3Description'}/>,
        cover: december3,
        path: path.newYearEve,
        startDate: '27/12',
        endDate: '31/12'
    },
    {
        title: <Text id={'carouselDecember4Title'}/>,
        description: <Text id={'carouselDecember4Description'}/>,
        cover: december4,
        path: path.fireCoal,
        startDate: '27/12',
        endDate: '31/12'
    },
]

export default carouselContent